<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-24 10:55:12
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-03 20:54:27
-->
<template>
  <div class="search-bar flex-center">
    <svg-icon class="search-icon" @click="handleToSearch" :class="{ active }" iconClass="search-with-basis"></svg-icon>
    <input
      class="search-input"
      maxlength="20"
      @keyup.enter="handleToSearch"
      v-model="searchValue"
      @focus="active = true"
      @focusout="active = false"
      :placeholder="$t('Header.SearhInput.placeholder')"
    />
  </div>
</template>
<script>
export default {
  name: "SearInput",
  data() {
    return {
      searchValue: "",
      active: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleToSearch() {
      let timestamp = Math.random().toString();
      this.$router.push({
        name: "Search",
        params: this.$route.params,
        query: { searchValue: this.searchValue, timestamp },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.search-bar {
  width: 100%;
  min-width: 119.9991px;
  height: 1.2308rem;
  position: relative;

  .search-icon {
    position: absolute;
    width: 0.6154rem;
    height: 0.6154rem;
    left: 0.4rem;
    fill: #fff;
    &.active {
      fill: rgba(000, 000, 000, 0.4);
    }
  }
  .search-input {
    outline: none;
    width: 100%;
    height: 100%;
    border: 0;
    text-indent: 1.2821rem;
    border-radius: 0.7179rem;
    background-color: transparent;
    font-size: 0.4359rem;
    letter-spacing: -0.0059rem;
    font-weight: 400;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: rgba(255, 255, 255, 0.2);
    transition: all 0.2s;
    color: rgba(255, 255, 255, 0.4);

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }
    &:focus-visible {
      color: rgba(000, 000, 000, 0.8);
      outline: -webkit-focus-ring-color auto 0;
      background-color: #ffffff;
      &::placeholder {
        color: rgba(000, 000, 000, 0.4);
      }
    }
  }
}
</style>
