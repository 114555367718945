<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-24 14:23:37
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-04 11:45:45
-->
<template>
  <div class="footer">
    <img class="img" :src="globalConfig.usageLogo" alt />
  </div>
</template>
<script>
export default {
  name: "Footer",
  computed: {
    globalConfig() {
      return this.$store.state.globalConfig;
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.footer {
  width: 100%;
  min-height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  //   background-color: #fff;
  .img {
    width: 0.8205rem;
    height: 0.8205rem;
    border-radius: 0.08rem;
  }
}
</style>
