<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-24 10:00:57
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-02 16:25:47
-->
<template>
  <div class="Header" :class="{ fixedHeight: !expand, hidden: $route.name == 'Search' }">
    <div class="header-content" :class="{ fixed: !expand }">
      <!-- 首页 -->
      <div id="header-top" class="head-top flex-center">
        <div class="app-info flex-center">
          <img @click="handleToHome" class="app-logo" :src="globalConfig.usageLogo" alt />
          <span class="product-name" @click="handleToHome">{{ defaultlanDetail.productName }}</span>
          <span class="line">｜</span>
          <span class="app-name" @click="handleToHome">{{ defaultlanDetail.usageName }}</span>
        </div>
        <localeSelect v-model="language" />
      </div>
      <!-- 欢迎语 -->
      <div class="welcome-info" v-show="expand">
        <div class="title">{{ defaultlanDetail.productTitle }}</div>
        <div class="remark">{{ defaultlanDetail.productDescribe }}</div>
      </div>
      <!-- 搜索框 -->
      <div class="search-input">
        <SearInput></SearInput>
      </div>
    </div>
  </div>
</template>
<script>
import localeSelect from "@/components/localeSelect.vue";
import SearInput from "./SearhInput";
import { getDetailByDefaultLan } from "@/utils/tools";
export default {
  name: "Header",
  components: { SearInput, localeSelect },
  props: {
    expand: {},
  },
  computed: {
    defaultlanDetail() {
      return this.$store.state.defaultlanDetail;
    },
    globalConfig() {
      return this.$store.state.globalConfig;
    },
  },
  data() {
    return {
      isShow: false,
      expandcope: false,
      value1: 0,
      language: "",
    };
  },
  watch: {
    language(language) {
      const wikiDetail = getDetailByDefaultLan(language, this.globalConfig.usageManageDetails);
      this.$i18n.locale = language;
      this.$store.commit("SetDefaultlanDetail", wikiDetail);
    },
  },
  created() {
    if (this.expand) {
      this.expandcope = this.expand;
    }
  },
  mounted() {
    this.language = this.$store.state.defaultlanguage;
  },
  methods: {
    Show() {
      this.isShow = !this.isShow;
    },
    handleToHome() {
      if (this.$route.name != "Home") {
        this.$router.push({ name: "Home", params: this.$route.params });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.hidden {
  display: none;
}
.Header {
  background-color: var(--theme-color);
  &.fixedHeight {
    height: 4.1026rem;
  }

  .header-content {
    left: 0;
    width: 100%;
    padding: 0 0.5128rem;
    padding-bottom: 0.6667rem;
    box-sizing: border-box;
    z-index: 2;
    background-color: var(--theme-color);
    &.fixed {
      //   position: fixed;
    }
  }

  .head-top {
    justify-content: space-between;
    height: 1.5641rem;

    .app-info {
      color: #ffffff;
      font-size: 0.4359rem;
      .app-logo {
        width: 0.8205rem;
        height: 0.8205rem;
        border-radius: 0.08rem;
      }
      .product-name {
        margin-left: 0.2051rem;
        font-weight: 600;
      }
      .line,
      .app-name {
        color: rgba(255, 255, 255, 0.8);
        font-weight: 400;
      }
    }
  }
  .welcome-info {
    margin-top: 0.6154rem;
    .title {
      font-size: 0.6154rem;
      color: #ffffff;
      text-align: center;
      font-weight: 600;
    }
    .remark {
      margin-top: 0.3077rem;
      font-size: 0.3846rem;
      color: rgba(255, 255, 255, 0.6);
      text-align: center;
      font-weight: 400;
    }
  }
  .search-input {
    margin-top: 0.6154rem;
  }
}
</style>
