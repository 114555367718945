<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-12-09 13:57:25
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-04 09:43:43
-->
<template>
  <div class="view">
    <Header :expand="expand"></Header>
    <router-view :key="routerKey"></router-view>
    <Footer />
  </div>
</template>
<script>
import Header from "./module/Header.vue";
import Footer from "./module/Footer.vue";
export default {
  components: { Header, Footer },
  data() {
    return {
      expand: false,
      routerKey: "",
      navName: "",
    };
  },
  computed: {},
  watch: {
    $route: {
      handler(val) {
        if (val.name == "Home") {
          this.expand = true;
        } else {
          this.expand = false;
        }
        this.routerKey = val.path;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.view {
  background-color: #f3f5f7;
}
</style>
